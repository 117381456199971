import { Grid, GridProps, LabelDisplayedRowsArgs, styled, TablePagination, tablePaginationClasses } from "@mui/material";
import { TFunction } from "i18next";
import PaginationActions from "./PaginationActions";
import PaginationActionsDense from "./PaginationActionsDense";
import { translationKeys } from "../../translations/main-translations";

const StyledTablePagination = styled(TablePagination)<{
    component: React.ElementType<GridProps>;
}>(({ theme }) => ({
    width: "100%",
    [`& .${tablePaginationClasses.toolbar}`]: {
        height: "inherit",
        minHeight: theme.spacing(6),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
    [`& .${tablePaginationClasses.selectLabel}`]: {
        color: theme.palette.text.secondary,
    },
    [`& .${tablePaginationClasses.displayedRows}`]: {
        color: theme.palette.text.secondary,
    },
    [`& .${tablePaginationClasses.selectRoot}`]: {
        marginLeft: (props: PaginationProps<unknown>) => theme.spacing(props.dense ? 0 : 1),
        marginRight: (props: PaginationProps<unknown>) => theme.spacing(props.dense ? 3 : 4),
    },
}));

interface PaginationProps<D> {
    count: number;
    pageSizeOptions: number[];
    page: number;
    pageSize: number;
    data: readonly D[];
    onPageChange: (newPage: number) => void;
    onPageSizeChange: (newPage: number) => void;
    itemName?: string;
    translate: TFunction;
    dense?: boolean;
}

function Pagination<D>(props: Readonly<PaginationProps<D>>) {
    const { count, pageSizeOptions, page, pageSize, data, onPageChange, onPageSizeChange, itemName, translate, dense } = props;

    const renderDisplayedRows = ({ from, to, count }: LabelDisplayedRowsArgs) => {
        const labelItemName = !dense && itemName != null ? ` ${translate(itemName)}` : "";
        return `${from}-${to} ${translate("of")} ${count}${labelItemName}`;
    };

    return (
        <StyledTablePagination
            labelRowsPerPage={translate(translationKeys.VDLANG_PAGE_SIZE)}
            labelDisplayedRows={renderDisplayedRows}
            rowsPerPageOptions={pageSizeOptions}
            count={count ?? data.length}
            rowsPerPage={pageSize}
            page={page}
            component={Grid}
            onPageChange={(e, newPage) => onPageChange(newPage)}
            onRowsPerPageChange={(e) => onPageSizeChange(+e.target.value)}
            ActionsComponent={dense ? PaginationActionsDense : PaginationActions}
        />
    );
}

export default Pagination;
